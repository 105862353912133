// Fonts
@font-face {
    font-family: 'Aljazeera';
    src: url('/fonts/Aljazeera Regular.ttf') format('truetype');
}

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import '~admin-lte-rtl-sp/dist/css/adminlte.css';
@import '~admin-lte-rtl-sp/dist/css/adminlte-rtl.css';

@import '~bootstrap-icons/font/bootstrap-icons.scss';
@import '~toastr/build/toastr.css';

.error-message {
    color: $red;
}

.align-text-right tr {
    text-align: right !important;
}

.spinner {
    width: 40px;
    height: 40px;

    position: relative;
    margin: 100px auto;
}

.double-bounce1,
.double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #333;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;

    -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
    animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {

    0%,
    100% {
        -webkit-transform: scale(0.0)
    }

    50% {
        -webkit-transform: scale(1.0)
    }
}

@keyframes sk-bounce {

    0%,
    100% {
        transform: scale(0.0);
        -webkit-transform: scale(0.0);
    }

    50% {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
    }
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid rgb(88, 97, 159);
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: rgb(88, 97, 159) transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.accordion {
    border: 1px solid #ccc;
    border-radius: 2px;
}
.accordion input {
    display: none;
}
.accordion__handler {
    padding: 10px;
    cursor: pointer;
    user-select: none;
    display: flex;
    justify-content: space-between;
}
.accordion__title {
    font-weight: bold;
}
.accordion__icon {
    transition: transform 250ms ease-in-out;
}
.accordion input:checked ~ .accordion__body {
    height: auto;
    padding: 0 10px 10px;
}
.accordion input:checked + .accordion__handler .accordion__icon {
    transform: rotate(180deg);
}
.accordion__body {
    height: 0;
    overflow: hidden;
}
